import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { FrontModule } from './front.module';
import { SignupComponent } from './Auth/signup/signup.component';
import { LoginComponent } from './Auth/login/login.component';
import { AuthGuard } from 'degineo-common';
import { AutologComponent } from 'degineo-common';
import { FrontLayoutComponent } from './front-layout/front-layout.component';
import { MyFilesComponent } from './Pages/my-files/my-files.component';
import { SignupConfirmComponent } from './Pages/signup-confirm/signup-confirm.component';
import { PrintStep1Component } from './Pages/print/print-step1/print-step1.component';
import { PrintStep2Component } from './Pages/print/print-step2/print-step2.component';
import { PrintGuard } from './Pages/print/print-guard.service';
import { PrintStep3Component } from './Pages/print/print-step3/print-step3.component';
import { PrintStep4Component } from './Pages/print/print-step4/print-step4.component';
import { PanierComponent } from './Pages/panier/panier.component';
import { ClientDevisListComponent } from './Pages/devis/client-devis-list/client-devis-list.component';
import { ClientDevisPageComponent } from './Pages/devis/client-devis-page/client-devis-page.component';
import { ClientDevisPreviewComponent } from './Pages/devis/client-devis-preview/client-devis-preview.component';
import { ClientDevisModificationComponent } from './Pages/devis/client-devis-modification/client-devis-modification.component';
import { ClientDevisRefusalComponent } from './Pages/devis/client-devis-refusal/client-devis-refusal.component';
import { MyPrintsComponent } from './Pages/my-prints/my-prints.component';
import { DelayedPaymentComponent } from './Pages/delayed-payment/delayed-payment.component';
import { InvoiceListComponent } from './Pages/invoice-list/invoice-list.component';
import { MyProfileComponent } from './Pages/my-profile/my-profile.component';
import { MyProducersComponent } from './Pages/my-producers/my-producers.component';
import { TunnelMarqueBlancheComponent } from './MarqueBlanche/tunnel-marque-blanche/tunnel-marque-blanche.component';
import { ForgotPwdComponent } from 'degineo-common';
import { ForgotPwdPopupComponent } from 'degineo-common';
import { environment } from 'environments/environment';
import { QuantityChoiceStepComponent } from './MarqueBlanche/steps/quantity-choice-step/quantity-choice-step.component';
import { FinishMarqueBlancheOrderComponent } from './Pages/finish-marque-blanche-order/finish-marque-blanche-order.component';
import { ValidationEmailComponent } from './Pages/validation-email/validation-email.component';
import { ValidationEmailResolverService } from './Pages/validation-email/validation-email-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: FrontLayoutComponent,
    //canActivate: [AuthGuard],
    //canActivateChild: [AuthGuard],
    children: [
      //without auth
      {
        path: 'validation-email/:token',
        component: ValidationEmailComponent,
        resolve: { user: ValidationEmailResolverService },
      },
      //with auth
      {
        path: '',
        redirectTo: '/impression',
        pathMatch: 'full',
        //component: NotFoundComponent,
        //canActivate: [AuthGuard],
      },
      {
        path: 'confirmation-inscription',
        component: SignupConfirmComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'mes-fichiers',
        component: MyFilesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'mes-imprimeurs-partenaires',
        component: MyProducersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'mon-profil',
        component: MyProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'panier',
        component: PanierComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'mes-factures',
        component: InvoiceListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'mes-impressions',
        component: MyPrintsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'impression',
        component: PrintStep1Component,
        canActivate: [AuthGuard, PrintGuard],
      },
      {
        path: 'impression/etape-2',
        component: PrintStep2Component,
        canActivate: [AuthGuard, PrintGuard],
      },
      {
        path: 'impression/etape-3',
        component: PrintStep3Component,
        canActivate: [AuthGuard, PrintGuard],
      },
      {
        path: 'impression/etape-4',
        component: PrintStep4Component,
        canActivate: [AuthGuard, PrintGuard],
      },
      {
        path: 'impression/etape-3/:token',
        component: PrintStep3Component,
        canActivate: [AuthGuard],
      },
      {
        path: ':slug_producteur/:idProducer/impression-en-ligne/finalisation-commande',
        component: FinishMarqueBlancheOrderComponent,
        canActivate: [AuthGuard],
      },
      {
        path: ':slug_producteur/:idProducer/demande-devis/finalisation-commande',
        component: FinishMarqueBlancheOrderComponent,
        canActivate: [AuthGuard],
      },
      { path: 'devis', component: ClientDevisListComponent, canActivate: [AuthGuard] },
      { path: 'devis/:id', component: ClientDevisPageComponent, canActivate: [AuthGuard] },
      { path: 'devis/:id/preview', component: ClientDevisPreviewComponent, canActivate: [AuthGuard] },
      { path: 'devis/:id/modifications', component: ClientDevisModificationComponent, canActivate: [AuthGuard] },
      { path: 'devis/:id/refusal', component: ClientDevisRefusalComponent, canActivate: [AuthGuard] },
      { path: 'paiement-differe/producteur/:id', component: DelayedPaymentComponent, canActivate: [AuthGuard] },
      { path: 'paiement-differe/facture/:id', component: DelayedPaymentComponent, canActivate: [AuthGuard] },
    ],
  },
  {
    path: 'connexion',
    component: LoginComponent,
  },
  {
    path: 'inscription/:token',
    component: SignupComponent,
  },
  {
    path: 'inscription',
    component: SignupComponent,
  },
  {
    path: 'mdp-oublie/:token',
    component: ForgotPwdComponent,
  },
  {
    path: 'autolog/:token',
    component: AutologComponent,
  },

  { path: ':slug_producteur/:idProducer/impression-en-ligne', component: TunnelMarqueBlancheComponent },
  { path: ':slug_producteur/:idProducer/demande-devis', component: TunnelMarqueBlancheComponent },
];

@NgModule({
  exports: [RouterModule, FrontModule],
  imports: [RouterModule.forChild(routes), FrontModule],
})
export class FrontRoutingModule {}
