import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from 'degineo-common';

const routes: Routes = [{ path: '**', component: NotFoundComponent }];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  providers: [],
})
export class AppRoutingModule {}
