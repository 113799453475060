import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { DegineoUserBundleV2, RegisterService, AuthService } from 'degineo-common';
import { Observable } from 'rxjs';
import { L } from 'ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class ValidationEmailResolverService implements Resolve<DegineoUserBundleV2> {
  constructor(private registerService: RegisterService, private authService: AuthService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<DegineoUserBundleV2> | Promise<DegineoUserBundleV2> | DegineoUserBundleV2 {
    return new Observable((obs) => {
      let token = route.paramMap.get('token');
      if (token === undefined) {
        this.router.navigate(['/connexion']);
      }
      this.registerService.confirmEmail(token).subscribe(
        (data) => {
          this.authService.loginWith(data, () => {
            obs.next(data);
            obs.complete();
          });
        },
        (err) => {
          L.e(err);
          this.router.navigate(['/connexion']);
          //obs.error(err);
          //obs.complete();
        }
      );
    });
  }
}
