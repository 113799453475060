import { Component, OnInit, Inject, NgZone, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivationEnd, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { IRPC } from 'ic2-lib';
import { Title, Meta } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IRPCBuffer } from './irpcBuffer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  _routerSub: Subscription;
  constructor(
    @Inject(Router) private router: Router,
    @Inject(IRPC) public irpc: IRPC,
    @Inject(NgZone) private ngZone: NgZone,
    @Inject(Title) private titleService: Title,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(Meta) private metaService: Meta,
    @Inject(IRPCBuffer) private IRPCBuffer: IRPCBuffer
  ) {}
  ngOnInit() {
    this._routerSub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        //console.log(route);
        if (route.data.title) this.titleService.setTitle('Degineo • ' + route.data.title);
        else this.titleService.setTitle('Degineo • Mon Espace Client');
        if (route.data.metadesc) this.metaService.updateTag({ property: 'description', content: route.data.metadesc });
        else this.metaService.updateTag({ property: 'description', content: '' });
      });

    //IFRAME BRIDGE COMPATIBILITY MODE :
    //console.log('waiting for token1');
    /*
    if (typeof window !== 'undefined') {
      //console.log('waiting for token2', window.frameElement);
      if (window.self !== window.top) {
        //console.log('waiting for token3');
        window.addEventListener('message', (event) => {
          if (event.data.id !== 'jwtToken') return;
          console.log('token received', event, event.data.token);
          this.irpc.authToken = event.data.token;
          this.IRPCBuffer.tokenReceived();

          document.body.style.overflow = 'hidden';

          this.ngZone.runOutsideAngular(() => {
            //https://github.com/angular/zone.js/issues/962

            let config = {
              attributes: true,
              attributeOldValue: true,
              characterData: true,
              characterDataOldValue: true,
              childList: true,
              subtree: true,
            };

            let observer = new MutationObserver(function(mutations) {
              //console.log(mutations);
              //console.log('mutation', document.body.offsetHeight);
              parent.postMessage({ id: 'iframeResize', data: document.body.offsetHeight }, '*');
            });

            //console.log('Setup MutationObserver');
            observer.observe(document.body, config);
          });
          parent.postMessage({ id: 'iframeResize', data: document.body.offsetHeight }, '*');
        });
      } else {
        //pas dans une iframe pour debug
        this.IRPCBuffer.tokenReceived();
      }
    }
    */
  }
  ngAfterViewInit() {
    //console.log('afterinit', document.body.offsetHeight);
    //parent.postMessage({ id: 'iframeResize', data: document.body.offsetHeight }, '*');
  }
  ngOnDestroy() {
    this._routerSub.unsubscribe();
  }
}
