import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { RpcRequestBuilder } from 'ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class IRPCBuffer {
  public subject: ReplaySubject<any>;
  constructor() {
    this.subject = new ReplaySubject();
  }
  buffer(rpcReq: RpcRequestBuilder<any>): Observable<any> {
    console.log('buffering', rpcReq.req.serviceDotMethod);
    return Observable.create((observer) => {
      this.subject.subscribe((data) => {
        rpcReq.subscribe(
          (data) => {
            observer.next(data);
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
      });
    });
  }
  tokenReceived() {
    console.log('launching buffered requests');
    this.subject.next(true);
    this.subject.complete();
  }
}
