import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, Inject, ApplicationRef, Type, ComponentFactoryResolver, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IRPC, Ic2Module, RpcError } from 'ic2-lib';
import { FormsModule } from '@angular/forms';
import { DegineoCommonModule, AuthService, ForgotPwdPopupComponent } from 'degineo-common';
import { AgmCoreModule } from '@agm/core';
import { APP_BASE_HREF, isPlatformBrowser } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FrontRoutingModule } from './Front/front-routing.module';
import { ActivatedRoute, Router, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { BrowserCookiesModule } from 'ngx-utils-cookies-port';
import { CookiesService } from 'ngx-utils-cookies-port';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { Intercom, IntercomModule } from 'ng-intercom';
import { NgxMaskModule } from 'ngx-mask';

const components = {
  'app-root': AppComponent,
};

@NgModule({
  declarations: [
    AppComponent,

    //Pages client a migrer vers un module client
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'degineo-app' }),
    AngularMyDatePickerModule,
    BrowserCookiesModule.forRoot(),
    HttpClientModule,
    Ic2Module,
    NgbModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    FrontRoutingModule,
    DegineoCommonModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gmapsApiKey,
    }),
    IntercomModule.forRoot({
      appId: environment.intercomAppId, // from your Intercom config
      updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    }),
    AppRoutingModule, //needs to be last
  ],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }, { provide: LOCALE_ID, useValue: 'fr' }, Title],
  bootstrap: [AppComponent],
  entryComponents: [ForgotPwdPopupComponent],
})
export class AppModule {
  constructor(
    @Inject(IRPC) public irpc: IRPC,
    activatedRoute: ActivatedRoute,
    router: Router,
    @Inject(PLATFORM_ID) private platform: Object,
    private cookiesService: CookiesService,
    public intercom: Intercom,
    authService: AuthService
  ) {
    if (isPlatformBrowser(this.platform)) {
      irpc.url = environment.rpcHost + 'rpc';
      if (this.irpc.authToken === null || this.irpc.authToken === undefined) {
        let token = cookiesService.get('token');
        //console.log(token);
        if (token === null || token === undefined || token == 'undefined') {
          return;
        }
        this.irpc.authToken = token;
      }
    } else {
      irpc.url = environment.ssrRpcHost + 'rpc';
    }
    irpc.log = environment.name !== 'PRODUCTION';

    irpc.interceptErrorHandlers.push((req, err) => {
      if (err.code === RpcError.NOT_LOGGED) {
        console.log('INTERCEPTED NOT LOGGED ERROR on ' + req.req.serviceDotMethod);
        authService.logout();
        router.navigate(['/connexion']);
        return true;
      }
      return false;
    });

    this.intercom.boot({
      app_id: environment.intercomAppId,
      /*
      alignment: 'left',
      horizontal_padding: 20,
      vertical_padding: 20,
      */
    });

    authService.loginEvent.subscribe((event) => {
      let name = event.userBundle.user.firstName + ' ' + event.userBundle.user.lastName;
      if (event.userBundle.user.firstName === null && event.userBundle.producerName !== null) {
        name = event.userBundle.producerName;
      }
      const data = {
        user_id: event.userBundle.user.idUser,
        email: event.userBundle.user.email,
        name: name,
        avatar: {
          type: 'avatar',
          image_url: environment.rpcHost + 'userLogo/' + event.userBundle.logoToken + '/logo.png',
        },
        company: {
          id: event.userBundle.client.id,
          name: event.userBundle.client.name,
        },
      };
      console.log('updating intercom as logged in user', data, this.intercom);
      this.intercom.update(data);
    });
  }
  ngDoBootstrap(appRef: ApplicationRef) {
    this.bootstrapRootComponent(appRef);
  }

  bootstrapRootComponent(app) {
    // define the possible bootstrap components
    // with their selectors (html host elements)
    let atLeastOneBootstraped = false;
    for (const [selector, component] of Object.entries(components)) {
      // console.log(selector,component);
      const el = document.querySelector(selector);
      if (el !== null) {
        app.bootstrap(component);
        atLeastOneBootstraped = true;
      }
    }
    /*if (!atLeastOneBootstraped)
      console.error('Could not find any component to bootstrap');*/
  }
}
